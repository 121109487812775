import React from "react"
import { Link as GatsbyLink } from "gatsby"

const Link = ({ children, to, activeClassName, partiallyActive, ...other }) => {
  const internal = /^\/(?!\/)/.test(to)

  if (internal) {
    return (
      <GatsbyLink
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...other}
      >
        {children}
      </GatsbyLink>
    )
  }
  return (
    <a rel="noopener noreferrer" target="_blank" href={to} {...other}>
      {children}
    </a>
  )
}
export default Link
